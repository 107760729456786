.p-inputtext {
     font-family: unset;
     font-size: unset; 
     color: unset; 
     background: unset; 
     padding: unset; 
     border: unset; 
     transition: background-color unset; 
     appearance: unset; 
     border-radius: unset; 
}