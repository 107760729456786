.wl-spinner {
    position: relative;
    display: flex;
    padding: 10rem;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .wl-spinner-logo {
        width: 3rem;
        height: 3rem;
        background: url("../../../../../assets/img/a.png");
        background-position: center;
        background-size: contain;
        z-index: 2;
    }

    .wl-spinner__particle {
        position: absolute;
        width: 5rem;
        height: 5rem;
        border-radius: 100%;
        opacity: 0;
        animation: pulsing 2s ease-in-out infinite;
        z-index: 1;

        &:nth-child(1) {
            background-color: var(--neutral-2);
            animation-delay: 0s;
        }

        &:nth-child(2) {
            background-color: var(--core-purple);
            animation-delay: .33s;
        }

        &:nth-child(3) {
            background-color: var(--core-green);
            animation-delay: .75s;
        }

        &:nth-child(4) {
            background-color: var(--core-blue);
            animation-delay: 1.25s;
        }

    }

    @keyframes pulsing {
        from { 
            transform: scale(.1); 
            opacity:1; 
        }
        to { 
            transform: scale(2); 
            opacity:0; 
        }
    }
}
