.p-dialog {

    .p-dialog-content {
        padding-bottom: 1rem;
    }
}

.locked-out-users-modal {

    h3 {        
        border-bottom: 0.1rem dotted #305ba733;
        padding-bottom: .5rem;
    }
}