h1, h2, h3, h4, h5, h6 {
    margin-block: 1rem;
}

h1 {

}

h2 {

}

h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
}

h4 {

}

h5 {

}

h6 {

}

p {
    margin-block: 1rem;
}

