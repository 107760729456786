.circ_graph {
    margin-right: 4rem;
    padding-right: 5rem;
    border-right: 0.1rem dotted #305ba733;
    
    .svg_wrapper {
        margin-right: 2rem;

        svg {            
        }
    }

    .circle-graph-data-table {
        display: grid;
        grid-template-columns: 2fr 20fr 3fr;
        gap: 1rem;
        margin-block: .5rem;
        align-items: center;

        span:last-of-type {
            text-align: right;
        }
    }
}

.circle-graph-dropdowns {
    display: flex;
    background: radial-gradient(at 50% 100%,#305ba70d 0,#305ba700 70%);
    border-bottom: 0.1rem dotted #305ba733;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    & > * {
        margin-right: 1rem;
    }
}

.circle-graph-info {
    display: flex;
    align-items: center;

    ul {
        font-weight: 700;
        list-style-type: none;
        padding: 0;

        li {
            margin-block: .75rem;
        }
        

        li:before {
            content: '\f007';
            font-family: "Font Awesome 5 Pro";
            font-weight: 500;
            margin-right: 1rem;
        }
    }
}