.p-inputtext {
  font-family: unset;
  font-size: unset;
  color: unset;
  background: unset;
  padding: unset;
  border: unset;
  transition: background-color unset;
  appearance: unset;
  border-radius: unset;
}

.button-container {
  padding: 0.75rem 0;
  padding-top: 1.75rem;
}
.button-container p-button {
  margin-inline: 0 0.5rem;
}

.p-float-label {
  margin-block: 0.5rem;
}
.p-float-label p {
  margin-block: 0.5rem;
}
.p-float-label.restrict-input-width {
  max-width: 50%;
}

.navigation-container {
  margin: 0;
}

.p-submenu-header {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-block: 1rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

p {
  margin-block: 1rem;
}

.colorbar {
  width: 100vw;
  height: 0.5rem;
  margin: 0;
  max-width: 100%;
}

.p-card, .card {
  box-shadow: -0.1rem -0.1rem 0.3rem rgba(48, 91, 167, 0.0509803922), 0.1rem 0.2rem 0.1rem rgba(30, 55, 99, 0.08), 0 0.15rem 1.75rem rgba(48, 91, 167, 0.1490196078);
}

.wl-spinner {
  position: relative;
  display: flex;
  padding: 10rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.wl-spinner .wl-spinner-logo {
  width: 3rem;
  height: 3rem;
  background: url("../../../../../assets/img/a.png");
  background-position: center;
  background-size: contain;
  z-index: 2;
}
.wl-spinner .wl-spinner__particle {
  position: absolute;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  opacity: 0;
  animation: pulsing 2s ease-in-out infinite;
  z-index: 1;
}
.wl-spinner .wl-spinner__particle:nth-child(1) {
  background-color: var(--neutral-2);
  animation-delay: 0s;
}
.wl-spinner .wl-spinner__particle:nth-child(2) {
  background-color: var(--core-purple);
  animation-delay: 0.33s;
}
.wl-spinner .wl-spinner__particle:nth-child(3) {
  background-color: var(--core-green);
  animation-delay: 0.75s;
}
.wl-spinner .wl-spinner__particle:nth-child(4) {
  background-color: var(--core-blue);
  animation-delay: 1.25s;
}
@keyframes pulsing {
  from {
    transform: scale(0.1);
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}

.main-container {
  max-width: 100%;
}

.p-dialog .p-dialog-content {
  padding-bottom: 1rem;
}

.locked-out-users-modal h3 {
  border-bottom: 0.1rem dotted rgba(48, 91, 167, 0.2);
  padding-bottom: 0.5rem;
}

.primary-login .p-card {
  width: 30rem;
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
  padding: 2rem;
}
.primary-login .p-card-content {
  padding: 0;
}
.primary-login .button-container {
  text-align: center;
}

.change-passwords .change-password-form {
  width: 30%;
}

.two-factor-auth {
  width: 40%;
}

.circ_graph {
  margin-right: 4rem;
  padding-right: 5rem;
  border-right: 0.1rem dotted rgba(48, 91, 167, 0.2);
}
.circ_graph .svg_wrapper {
  margin-right: 2rem;
}
.circ_graph .circle-graph-data-table {
  display: grid;
  grid-template-columns: 2fr 20fr 3fr;
  gap: 1rem;
  margin-block: 0.5rem;
  align-items: center;
}
.circ_graph .circle-graph-data-table span:last-of-type {
  text-align: right;
}

.circle-graph-dropdowns {
  display: flex;
  background: radial-gradient(at 50% 100%, rgba(48, 91, 167, 0.0509803922) 0, rgba(48, 91, 167, 0) 70%);
  border-bottom: 0.1rem dotted rgba(48, 91, 167, 0.2);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.circle-graph-dropdowns > * {
  margin-right: 1rem;
}

.circle-graph-info {
  display: flex;
  align-items: center;
}
.circle-graph-info ul {
  font-weight: 700;
  list-style-type: none;
  padding: 0;
}
.circle-graph-info ul li {
  margin-block: 0.75rem;
}
.circle-graph-info ul li:before {
  content: "\f007";
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
  margin-right: 1rem;
}

.thought-leadership {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
}
.thought-leadership div {
  box-shadow: -0.1rem -0.1rem 0.3rem rgba(48, 91, 167, 0.0509803922), 0.1rem 0.2rem 0.1rem rgba(30, 55, 99, 0.08), 0 0.15rem 1.75rem rgba(48, 91, 167, 0.1490196078);
  border-radius: 0.625rem;
  padding: 2.5rem;
}
.thought-leadership div .myimage, .thought-leadership div .mycontent {
  grid-column: auto;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}
.thought-leadership div:nth-child(1) {
  grid-column: span 3;
}
.thought-leadership div:nth-child(1) .home-thought-leadership {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "mycontent myimage";
  gap: 1rem;
  align-items: center;
}
.thought-leadership div:nth-child(1) .myimage {
  grid-area: myimage;
  height: 100%;
}
.thought-leadership div:nth-child(1) .myimage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.thought-leadership div:nth-child(1) .mycontent {
  grid-area: mycontent;
}
.thought-leadership div:nth-child(1) .mycontent h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  max-width: 40ch;
}
.thought-leadership div:nth-child(1) .mycontent .status-new {
  display: inline-block;
  background: var(--covered-orange);
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
}
.thought-leadership div:nth-child(2) {
  grid-column: span 2;
}
.thought-leadership div:nth-child(2) .home-thought-leadership {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-areas: "mycontent myimage";
  gap: 1rem;
  height: 100%;
  align-items: center;
}
.thought-leadership div:nth-child(2) .myimage {
  grid-area: myimage;
  height: 100%;
}
.thought-leadership div:nth-child(2) .myimage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.thought-leadership div:nth-child(2) .mycontent {
  grid-area: mycontent;
}
.thought-leadership div:nth-child(2) .mycontent h3 {
  font-size: 1.25rem;
  line-height: 1.6rem;
}
.thought-leadership div:nth-child(2) .status-new {
  display: none;
}
.thought-leadership div:nth-child(n+3) {
  grid-column: span 1;
}
.thought-leadership div:nth-child(n+3) .home-thought-leadership {
  display: block;
}
.thought-leadership div:nth-child(n+3) h3 {
  font-size: 1rem;
}
.thought-leadership div:nth-child(n+3) .myimage {
  height: 15rem;
}
.thought-leadership div:nth-child(n+3) .myimage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.thought-leadership div:nth-child(n+3) .text-body {
  display: none;
}
.thought-leadership div:nth-child(n+3) .status-new {
  display: none;
}
.thought-leadership div:nth-child(n+3) .link {
  margin-bottom: 0;
}

.home-thought-leadership .p-card {
  height: 100%;
}
.home-thought-leadership .p-card .p-card-content h3 {
  margin-top: 0;
}

.inline-center {
  text-align: center;
}

/* -------------------------------------  below vars/_colors.scss  ------------------------------------- */
:root {
  /* Bad Colors */
  --core-green: rgb(143, 227, 211);
  --core-blue: rgb(161, 195, 253);
  --core-purple: rgb(195, 171, 218);
  --core-blue-light: rgb(161, 195, 253, 0.1);
  --tertiary-blue:rgb(220, 233, 255);
  --core-green-light: rgb(218, 221, 227);
  --core-blue-light: rgb(233, 240, 253);
  --core-purple-light: rgb(239, 234, 245);
  --core-green-lightdesat: rgb(27, 66, 54);
  --core-green-desat: rgb(75, 92, 118);
  --accent-yellow: rgb(255, 242, 0);
  --accent-orange: rgb(255, 153, 102);
  --accent-blue: rgb(39, 109, 226);
  --accent-blue-hover: rgb(22, 91, 207);
  --tert-green: rgb(100, 217, 210);
  --tert-blue: rgb(99, 158, 254);
  --tert-blue-darker: rgb(69, 123, 209);
  --tert-purple: rgb(187, 109, 185);
  --tert-pink: rgb(211, 130, 224);
  --neutral-white: rgb(255, 255, 255);
  --neutral-2: rgb(239, 239, 239);
  --neutral-3: rgb(222, 222, 222);
  --neutral-4: rgb(149, 149, 149);
  --neutral-5: rgb(102, 102, 102);
  --neutral-6: rgb(121, 132, 131);
  --neutral-dark: rgb(40, 40, 40);
  --covered-green: rgb(14,171,0);
  --covered-orange: rgb(251,207,54);
  --covered-red: rgb(227,26,26);
  --error-pink: rgb(253, 236, 236);
  --neutral-dark-t10: rgba(42, 62, 92, .1);
  --neutral-dark-t25: rgba(42, 62, 92, .25);
  --neutral-dark-t50: rgba(42, 62, 92, .5);
  --gradient-1: linear-gradient(90deg, rgba(255, 242, 0, 1) 0%, rgba(143, 227, 211, 1) 75%);
  --gradient-2: linear-gradient(90deg, rgba(255, 242, 0, 1) 0%, rgba(195, 171, 218, 1) 35%);
  --gradient-3: linear-gradient(90deg, rgba(255, 242, 0, 1) 0%, rgba(161, 195, 253, 1) 35%);
  --gradient-4: linear-gradient(90deg, rgba(255, 242, 0, 1) 0%, rgba(255, 153, 102, 1) 35%);
  --gradient-whiteToGray: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 24%);
  --gradient-5: linear-gradient(5deg, rgb(255, 250, 154) 0%, rgb(193, 231, 167) 15%, rgba(143, 227, 211, 1) 65%);
  --dark-blue-1: rgb(42, 62, 92);
  --dark-blue-2: rgb(75, 92, 118);
  --dark-blue-3: rgb(109, 124, 145);
  --dark-blue-4: rgb(145, 156, 172);
  --dark-blue-5: rgb(180, 188, 199);
  --dark-blue-5: rgb(218, 221, 227);
  /* Commonly used properties */
  --fillet-lg: .5rem;
  --fillet-md: .25rem;
  --fillet-sm: .2rem;
  --warning20: rgb(255, 240, 210);
  --warning-hover:rgb(178, 121, 10);
  --success: rgb(45, 190, 77);
  --success-hover:rgb(34, 142, 58);
  --warning: rgb(251, 184, 54);
  --error: rgb(238, 63, 63);
  --error-hover: rgb(198, 26, 26);
  --error_soft: rgb(253, 236, 236);
  --error-msg: rgb(198, 26, 26);
}

.teal {
  color: var(--core-green);
}

.white {
  color: var(--neutral-white);
}

.green {
  color: var(--success);
}

/* -------------------------------------  above vars/_colors.scss  ------------------------------------- */
/* -------------------------------------  below vars/_vars.scss  ------------------------------------- */
/* -------------------------------------  above vars/_vars.scss  ------------------------------------- */