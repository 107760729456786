.thought-leadership {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;

    & div {        
        box-shadow: -0.1rem -0.1rem 0.3rem #305ba70d, 0.1rem 0.2rem 0.1rem rgb(30 55 99 / 8%), 0 0.15rem 1.75rem #305ba726;
        border-radius: .625rem;
        padding: 2.5rem;

        .myimage, .mycontent {
            grid-column: auto;
            box-shadow: none;
            border-radius: 0;
            padding: 0;
        }
    }

    & div:nth-child(1) {
        grid-column: span 3;
        
        .home-thought-leadership {
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-template-areas: 
                "mycontent myimage"
            ;
            gap: 1rem;
            align-items: center;
        }
                
        .myimage {
            grid-area: myimage;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .mycontent {
            grid-area: mycontent;

            h3 {
                font-size: 1.5rem;
                line-height: 2rem;
                max-width: 40ch;
            }

            .status-new {
                display: inline-block;
                background: var(--covered-orange);
                padding: .75rem 1rem;
                border-radius: .25rem;
            }
        }
    }
    
    & div:nth-child(2) {
        grid-column: span 2;
        
        .home-thought-leadership {
            display: grid;
            grid-template-columns: 3fr 2fr;
            grid-template-areas: 
                "mycontent myimage"
            ;
            gap: 1rem;
            height: 100%;
            align-items: center;
        }

        .myimage {
            grid-area: myimage;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .mycontent {
            grid-area: mycontent;

            h3 {
                font-size: 1.25rem;
                line-height: 1.6rem;
            }
        }

        .status-new {
            display: none;
        }
    }

    & div:nth-child(n + 3 ) {
        grid-column: span 1;
        
        .home-thought-leadership {
            display: block;
        }

        h3 {
            font-size: 1rem;
        }
        
        .myimage {
            height: 15rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

        .text-body {
            display: none;
        }

        .status-new {
            display: none;
        }

        .link {
            margin-bottom: 0;
        }
    }

}

.home-thought-leadership {
    
    .p-card {
        height: 100%;

        .p-card-content {

            h3 {
                margin-top: 0;
            }
        }
    }
}