.p-float-label {
    margin-block: .5rem;

    p {
        margin-block: .5rem;
    }

    &.restrict-input-width {
        max-width: 50%;
    }

}