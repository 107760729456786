/* -------------------------------------  below vars/_vars.scss  ------------------------------------- */
$border_radius:converts(100, px, rem);
$box_radius:converts(10, px, rem);
$card_shadow:0 converts(5, px, rem) converts(5, px, rem) var(--neutral-5);
$button_radius:converts(100, px, rem);




$box_shadow1:0 converts(4, px, rem) converts(8, px, rem) var(--neutral-3);
$box_shadow2: 0 converts(20, px, rem) converts(50, px, rem) var(--neutral-3);




/* -------------------------------------  above vars/_vars.scss  ------------------------------------- */
