.primary-login {

    .p-card {
        width: 30rem;
        margin: 0 auto;
        padding-right: 0;
        padding-left: 0;
        padding: 2rem;
    }

    .p-card-content {
        padding: 0;

    }

    .button-container {
        text-align: center;
    }
}

.change-passwords {

    .change-password-form {
        width: 30%;
    }
}

.two-factor-auth {
    width: 40%;
}