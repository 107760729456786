/* -------------------------------------  below vars/_colors.scss  ------------------------------------- */


$gradient1:linear-gradient(92.75deg, #FFF200 0%, #F3F018 2.28%, #DEEE41 6.75%, #CBEB67 11.77%, #BBE987 17.23%, #ADE7A3 23.26%, #A2E6B9 30.08%, #99E4C9 38.04%, #93E4D5 47.88%, #90E3DC 61.68%, #8FE3DE 100%);
$gradient2: linear-gradient(92.75deg, #FFF200 0%, #FCF107 0.57%, #EBE835 4.61%, #DBE060 9.16%, #CDD986 14.05%, #C1D3A6 19.37%, #B7CEC2 25.25%, #AFCAD8 31.89%, #A9C7E9 39.65%, #A4C5F4 49.23%, #A2C3FB 62.68%, #A1C3FD 100%);
$gradient3:linear-gradient(92.75deg, #FFF200 0%, #F9EB14 1.93%, #EEDE3D 6.41%, #E4D263 11.45%, #DBC783 16.93%, #D3BE9F 22.99%, #CDB7B5 29.83%, #C9B2C5 37.81%, #C5AED1 47.69%, #C4ACD8 61.54%, #C3ABDA 100%);
$gradient4: linear-gradient(92.75deg, #FFF200 0%, #FFE410 3.38%, #FFCD2B 10.3%, #FFBA41 18.27%, #FFAB52 27.57%, #FFA15D 39.07%, #FF9B64 55.2%, #FF9966 100%);





:root {

  /* Bad Colors */
  --core-green: rgb(143, 227, 211);
  --core-blue: rgb(161, 195, 253);
  --core-purple: rgb(195, 171, 218);

  --core-blue-light: rgb(161, 195, 253, 0.1);

  --tertiary-blue:rgb(220, 233, 255);


  --core-green-light: rgb(218, 221, 227);
  --core-blue-light: rgb(233, 240, 253);
  --core-purple-light: rgb(239, 234, 245);

  --core-green-lightdesat: rgb(27, 66, 54);
  --core-green-desat: rgb(75, 92, 118);

  --accent-yellow: rgb(255, 242, 0);
  --accent-orange: rgb(255, 153, 102);

  --accent-blue: rgb(39, 109, 226); // new blue from ADS
  --accent-blue-hover: rgb(22, 91, 207); // new from ADS



  --tert-green: rgb(100, 217, 210);
  --tert-blue: rgb(99, 158, 254);
  --tert-blue-darker: rgb(69, 123, 209);
  --tert-purple: rgb(187, 109, 185);
  --tert-pink: rgb(211, 130, 224);

  --neutral-white: rgb(255, 255, 255);
  --neutral-2: rgb(239, 239, 239);
  --neutral-3: rgb(222, 222, 222);
  --neutral-4: rgb(149, 149, 149);
  --neutral-5: rgb(102, 102, 102);
  --neutral-6: rgb(121, 132, 131);
  --neutral-dark: rgb(40, 40, 40);

  --covered-green: rgb(14,171,0);
  --covered-orange: rgb(251,207,54);
  --covered-red: rgb(227,26,26);



  --error-pink: rgb(253, 236, 236);




  --neutral-dark-t10: rgba(42, 62, 92, .1);
  --neutral-dark-t25: rgba(42, 62, 92, .25);
  --neutral-dark-t50: rgba(42, 62, 92, .5);

  --gradient-1: linear-gradient(90deg, rgba(255, 242, 0, 1) 0%, rgba(143, 227, 211, 1) 75%);
  --gradient-2: linear-gradient(90deg, rgba(255, 242, 0, 1) 0%, rgba(195, 171, 218, 1) 35%);
  --gradient-3: linear-gradient(90deg, rgba(255, 242, 0, 1) 0%, rgba(161, 195, 253, 1) 35%);
  --gradient-4: linear-gradient(90deg, rgba(255, 242, 0, 1) 0%, rgba(255, 153, 102, 1) 35%);


  --gradient-whiteToGray: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 24%);

  --gradient-5: linear-gradient(5deg, rgb(255, 250, 154) 0%, rgb(193, 231, 167) 15%, rgba(143, 227, 211, 1) 65%);

  --dark-blue-1: rgb(42, 62, 92);
  --dark-blue-2: rgb(75, 92, 118);
  --dark-blue-3: rgb(109, 124, 145);
  --dark-blue-4: rgb(145, 156, 172);
  --dark-blue-5: rgb(180, 188, 199);
  --dark-blue-5: rgb(218, 221, 227);

  /* Commonly used properties */
  --fillet-lg: .5rem;
  --fillet-md: .25rem;
  --fillet-sm: .2rem;




  --warning20: rgb(255, 240, 210);
  --warning-hover:rgb(178, 121, 10);
  --success: rgb(45, 190, 77);
  --success-hover:rgb(34, 142, 58);
  --warning: rgb(251, 184, 54);
  --error: rgb(238, 63, 63);
  --error-hover: rgb(198, 26, 26);
  --error_soft: rgb(253, 236, 236);
  --error-msg: rgb(198, 26, 26);



}

.teal {
  color: var(--core-green);
}

.white {
  color: var(--neutral-white);
}

.green {
  color: var(--success);
}

$darkPurple: darken(rgb(195, 171, 218), 10%);
$success_active:lighten(rgb(45, 190, 77), 15%);
$warning_active:lighten(#FBB836, 15%);
$error_active:lighten(#EE3F3F, 15%);

/* -------------------------------------  above vars/_colors.scss  ------------------------------------- */
